import clientInstance from "./clientInstance"

const UserService = {
    getUserList: async (params) => {
        return await clientInstance.get(
            '/user',
            {
                params: params,
            }
        )
    },
    getUser: async (id) => {
        return await clientInstance.get(
            '/user/' + id
        )
    },
    getUserEdit: async (id) => {
        return await clientInstance.get(
            '/user/' + id + '/edit'
        )
    },
    initUser: async () => {
        return await clientInstance.get(
            '/user/create'
        )
    },
    createUser: async (params) => {
        return await clientInstance.post(
            '/user',
            params
        )
    },
    updateUser: async (id, params) => {
        return await clientInstance.put(
            '/user/' + id,
            params
        )
    },
    deleteUser: async (id) => {
        return await clientInstance.delete(
            '/user/' + id
        )
    },
    getAuth: async () => {
        return await clientInstance.get(
            '/me'
        )
    }
}

export default UserService
