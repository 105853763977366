import React, {useEffect, useState} from 'react'
import AuthService from '../libs/service/AuthService';
import AuthContext from './AuthContext';
import UserService from '../libs/service/UserService';

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({})
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const toggleAuth = () => {
        setIsAuthenticated(!isAuthenticated)
        localStorage.clear()
    }

    function handleSubmit(formData) {
        setIsSubmitted(true)
        AuthService.login(formData)
            .then(response => {
                setIsSubmitted(false)
                setAuth(response)
                setIsAuthenticated(true)
                setErrorMessage('')
                getPermissionAuth()
            })
            .catch(error => {
                setIsSubmitted(false)
                setErrorMessage(error.response.data.message ? error.response.data.message : error.response.statusText)
            })
    }

    const [PermissionAuth, setPermissionAuth] = useState([]);
    const getPermissionAuth = () => {
        const res = UserService.getAuth();
        res.then(response => {
            // console.log(response.data)
            setPermissionAuth(response.data)
        }).catch(error => {
            // Global.errorMessage(error.response)
            console.log(error)
        })
    }

    useEffect(() => {
        setAuth(
            localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
        )

        // // clear all user acccount logged at 1749887090
        // if (auth.length && auth.authorisation.expires_at <= 1749887090) {
        //     localStorage.clear()
        // }
    }, [])

    const authContext = {
        auth,
        isSubmitted,
        isAuthenticated,
        toggleAuth,
        handleSubmit,
        errorMessage,
        setErrorMessage,
        // hasRenderUser,
        getPermissionAuth,
        PermissionAuth,
        setPermissionAuth
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
