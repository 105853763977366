import Util from "./Util"
import {ceil, floor} from 'lodash';
import Swal from 'sweetalert2'

const allHolidayList = () => {
    return [
        {
            id: 1,
            name: 'New Year’s Day (Tết Dương lịch) - 1/1',
            manual: false
        },
        {
            id: 2,
            name: 'Lunar New Year (Tết Nguyên Đán từ mùng 1 tháng 1 Âm lịch)',
            manual: true
        },
        {
            id: 3,
            name: 'Hung Kings Commemoration Day (Ngày Giỗ Tổ Hùng Vương mùng 10 tháng 3 Âm lịch)',
            manual: true
        },
        {
            id: 4,
            name: 'Reunification Day (Thống nhất đất nước) - 30/4',
            manual: false
        },
        {
            id: 5,
            name: 'International Workers Day (Ngày Quốc tế Lao động) - 1/5',
            manual: false
        },
        {
            id: 6,
            name: 'National Day (Ngày Quốc khánh) - 2/9',
            manual: false
        }
    ]
}

export default {
    logo: (url) => {
        return <img src={url} alt="B Solution Services" height="36px"/>
    },
    brandName: () => {
        return 'B Solution Services'
    },
    deleteMessage: () => {
        return "You won't be able to revert this!"
    },
    changeLeaderMessage: () => {
        return 'Are you sure want to change leader'
    },
    changeStatusMessage: () => {
        return 'Are you sure want to change status'
    },
    placeholderSearch: () => {
        return 'Search ...'
    },
    exportExcelMessage: () => {
        return 'Are you sure want to export Excel'
    },
    placeholderDate: () => {
        return 'MM/YYYY'
    },
    /**
     * no auth
     * @returns RSX
     */
    reLogin: () => {
        return (
            <div className="text-danger text-center mt-4">
                You do not have permission to access for this page!
                <br/>
                <a href={process.env.REACT_APP_PUBLIC_URL + "/"} className="btn btn-warning">Login</a> Try again!
            </div>
        )
    },
    /**
     * RSX show messages after action
     *
     * @param {string} message
     * @param {string} className
     * @returns
     */
    renderErrorMessage: (message, className = "text-danger") => {
        return (
            <div className={className}>
                {message}
            </div>
        )
    },

    // warning use lib
    warning: (message, status) => {

        return Swal.fire({
            icon: status ? "success" : "error",
            text:message
        })
        // return alert(message)
    },

    confirm: (message) => {
        return window.confirm(message)
    },

    // Database difine
    // user role
    isSuperAdmin: (type) => {
        return type === 1 || type === '1' || type === 'super-admin'
    },
    userRole: (type) => {
        return type === 1 || type === '1' || type === 'super-admin' ? 'Admin' : 'User'
    },
    userRoleSuperAdmin: () => {
        return 'Admin'
    },
    userRoleUser: () => {
        return 'User'
    },

    //user leader
    isLeader: (isLeader) => {
        return isLeader === 1 || isLeader === '1'
    },
    userLeader: () => {
        return 'Yes'
    },
    userMember: () => {
        return 'No'
    },
    // db employee define
    employeeStatus: (status) => {
        return status === 0 ? 'Employed' : 'Fired'
    },
    employeeIsForeign: (isForeign) => {
        return isForeign === 0 ? 'Insider' : 'Foreigner'
    },
    isEmployeeStatus: (status) => {
        return status === 0 ||  status === '0'
    },

    employeeNightshift: (nightshift) => {
        var val = 'Day'
        switch (nightshift) {
            case 1:
                val = 'Night'
                break;
            case 2:
                val = 'Day+Night'
                break;
            default:
                val = 'Day'
                break;
        }

        return val
    },

    employeeTime: (timeOption) => {
        var val = ''
        switch (timeOption) {
            case 1:
                val = '11-20 20-5'
                break;
            case 2:
                val = '8-17'
                break;
            case 3:
                val = '10-19'
                break;
            case 4:
                val = '12-24'
                break;
            case 5:
                val = '20-8'
                break;
            case 6:
                val = '15-24'
                break;
            case 7:
                val = '9-18'
                break;
            default:
                val = '11-20 20-5'
                break;
        }

        return val
    },

    isAutoUpdate: (status) => {
        return status === 1 ? 'Yes' : 'No'
    },

    employeeGroup: (groupId) => {
        var val = ''
        switch (groupId) {
            case 1:
                val = 'GBG'
                break;
            case 2:
                val = 'DEV & IT'
                break;
            case 3:
                val = 'Football Ticket Net'
                break;
            case 4:
                val = 'Gigsberg'
                break;
            default:
                val = 'GBG'
                break;
        }

        return val
    },
    numberDayShift(number) {
        return number
    },
    numberNightShift(number) {
        return number
    },

    dayOfWeek(dayofweek) {
        let result = dayofweek
        if (dayofweek == 'Mon,Tue,Wed,Thu,Fri,Sat,Sun') {
            result = 'All Week'
        }
        if (dayofweek == 'Mon,Tue,Wed,Thu,Fri') {
            result = 'Mon-Fri'
        }

        return result
    },
    // company define

    // timesheet define
    timesheetStatus: (status) => {
        return status === 0 ? 'Auto' : ''
    },

    timesheetIsCheck: (lastCheck, status) => {
        return status === 0
            ? 'IN'
            : status === 1
                ? 'OUT'
                : (lastCheck == 0 ? 'IN': 'OUT')
    },

    getIsCheckMore: (labelCheck, status) => {
        let isCheck = null
        if (labelCheck == 'IN' && status == null ) {
            isCheck = 0
        }
        if (labelCheck == 'OUT' && status == null ) {
            isCheck = 1
        }

        return isCheck
    },

    timesheetIsCheckStyle: (status) => {
        return status === 0
            ? 'btn btn-light border border-success text-warning'
            : status === 1
                ? 'btn btn-success text-warning'
                : 'btn btn-warning text-danger'
    },

    timesheetSelectTime: (status) => {
        var val = ''
        switch (status) {
            case 1:
                val = '05:00:00'
                break;
            case 2:
                val = '11:00:00'
                break;
            case 3:
                val = '20:00:00'
                break;
            default:
                val = "Select time"
                break;
        }

        return val
    },
    // format time number 2 charactor
    pad: (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString()
    },

    checkTime: (time) => {
        return (Util.getTime(time) !== "11:00:00"
            || Util.getTime(time) !== "05:00:00"
            || Util.getTime(time) !== "20:00:00")
    },

    // for total hour
    getHourOption: (timeOption) => {
        return (typeof timeOption.find((o, i) => o == '4' || o == '5') === 'undefined' ? 9 : 12)
    },

    totalWorkTime: function (hours, minutes)  {
        return minutes > 60
            ? this.pad(hours + floor(minutes/60)) + ':' +  this.pad(floor((minutes/60 - floor(minutes/60)) * 60))
            : this.pad(hours) + ':' + this.pad(minutes)
    },

     // work_dates define
     workdateHoliday: (status) => {
        return status === 0 ? 'No' : 'Yes'
    },
    workdateWeekend: (status) => {
        return status === 0 ? 'No' : 'Yes'
    },
    workdateCompanyTrip: (status) => {
        return status === 0 ? 'No' : 'Yes'
    },
    holidayType: (type) => {
        var val = 'N/A'
        switch (type) {
            case 0:
                val = 'N/A'
                break;
            case 1:
                val = 'First'
                break;
            case 2:
                val = 'Last'
                break;
        }

        return val
    },

    //workday mapping with shiptype
    workShiftType: (dayOfWeek, shiftType) => {
        let shiftTypeName = 'OFF'

        if (dayOfWeek > 0 && dayOfWeek <= 7) {
            if (shiftType == 0) {
                shiftTypeName = 'D'
            }
            if (shiftType == 1) {
                shiftTypeName = 'N'
            }
            if (shiftType == 2) {
                if (dayOfWeek < 4)
                    shiftTypeName = 'D'
                else
                    shiftTypeName = 'D'
            }
        }

        return shiftTypeName
    },

    // schedule
    getScheduleEmployeeId: (key_string) => {
        return key_string.split(',')[1]
    },
    getScheduleOrderby: (key_string) => {

        return Number(key_string.split(',')[0])
    },
    getMonthYear: (month, key) => {

        return month.split('-')[key] ? Number(month.split('-')[key]) : 0
    },

    // ------------------ USEAGE ---------------
    // <div className="fulljustify-text" dangerouslySetInnerHTML={{__html: Global.nickNameFW(employeeSearch.nick_name)}}></div>
    // ------------------ USEAGE ---------------
    nickNameFW: (nickName) => {
        var rsxText = '';
        var words = nickName.split("");
        // console.log(words)``
        words.map((item, index) => {
            if(item === ' '){
                rsxText += '<span>&nbsp;</span>';
            } else {
                rsxText += "<span>"+ item + "</span>";
            }
        });

        return rsxText
    },

    //Error Message 401
    //Error Message 403
    messageError:(response) => {
        let redirect = false
        // Unauthenticated.
        if (response.status === 401) {
            localStorage.clear()
            //call api refresh token.
            //UserService.refreshToken()
            redirect = true
        }
        // Permission Deny: redirect after 1.5 s
        if (response.status === 403) {
            redirect = true
        }
        if (redirect) {
            Swal.fire({
                icon: 'error', //icon: success, error, warning, info, question
                title: response.data.message ,
                showConfirmButton: false,
                timer: 1500
            })
            setTimeout(() => {
                window.location.href = process.env.REACT_APP_PUBLIC_URL + "/"
            }, 2000);
        }

        return redirect
    },
    getHoliday: (id) => {
        let holiday = allHolidayList().find(item => item.id === id);

        // return JSON.stringify(holiday);
        return holiday
    },
    allHolidayList,
    getHolidayByManual: (manual) => {
        let holiday = allHolidayList().filter(item => item.manual === manual);

        // console.log(JSON.stringify(holiday));
        return holiday
    },
}
