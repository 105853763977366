import React, { useDeferredValue } from 'react'
import Global from '../../libs/Global'
import uuid from 'react-uuid'

const MenuAdmin = (props) => {
    const {
        menu,
        onClickMenu,
        auth,
        PermissionAuth
    } = props

    const menuList = useDeferredValue(PermissionAuth && PermissionAuth.status == 'success'
            && PermissionAuth.permissions
            .filter(v => v.checked == true)
            .map(item => item.menu)
    );

    // const query = '-list'
    // const re = RegExp(`.*${query.toLowerCase().split('').join('.*')}.*`)
    // const loadMenuList = (
    //     <>
    //     {PermissionAuth && PermissionAuth.status == 'success'
    //         && PermissionAuth.permissions.filter(v => v.name.toLowerCase().match(re)).map((item, key) => (
    //         <div key={uuid()}>
    //             {(item.name.split("-"))[0]}
    //         </div>
    //     ))}
    //     </>
    // )

    // return auth && auth.user && auth.status === 'success' && Global.isSuperAdmin(auth.user.type) ? (
    //     <header className="navbar navbar-expand-lg navbar-expand-md">
    //         <div style={{
    //             display:"none"
    //         }}>
    //         </div>
    //         <div className="container">
    //             <button className="bg-success navbar-toggler" type="button" data-bs-toggle="collapse"
    //                     data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
    //                     aria-label="Toggle navigation">
    //                 <span className="navbar-toggler-icon"/>
    //             </button>
    //             <div className="collapse navbar-collapse" id="navbarNav">
    //                 <ul className="navbar-nav navbar-nav me-auto mb-2 mb-lg-0">
    //                     {menuList && menuList.includes('employee') && (
    //                         <li className="nav-item">
    //                             <a className={menu === 'employee' ? "nav-link link-primary active" : "nav-link"}
    //                             href="employee"
    //                             onClick={() => onClickMenu("employee")}
    //                             ><i className="bi bi-person-workspace"></i> Employee</a>
    //                         </li>
    //                     )}
    //                     {menuList && menuList.includes('timesheet') && (
    //                         <li className="nav-item">
    //                             <a className={menu === 'timesheet' ? "nav-link link-primary active" : "nav-link"}
    //                             href="timesheet"
    //                             onClick={() => onClickMenu("timesheet")}
    //                             ><i className="bi bi-clock-fill"></i> Time Log</a>
    //                         </li>
    //                     )}
    //                     {menuList && menuList.includes('timesheet') && (
    //                         <li className="nav-item">
    //                             <a className={menu === 'schedule' ? "nav-link link-primary active" : "nav-link"}
    //                             href="schedule"
    //                             onClick={() => onClickMenu("schedule")}
    //                             ><i className="bi bi-calendar3"></i> Schedule</a>
    //                         </li>
    //                     )}

    //                     <li className="nav-item">
    //                         <a className={menu === 'report' ? "nav-link link-primary active" : "nav-link"}
    //                            href="report"
    //                            onClick={() => onClickMenu("report")}
    //                         ><i className="bi bi-graph-up-arrow"></i> Report</a>
    //                     </li>

    //                     <div className="dropdown nav-item">
    //                         <a className={(
    //                             menu === 'user'
    //                             || menu === 'workday'
    //                             || menu === 'workday'
    //                             || menu === 'role'
    //                             || menu === 'office'
    //                             || menu === 'department'
    //                         )? "dropdown-toggle nav-link link-primary active" : "dropdown-toggle nav-link"}
    //                            data-bs-toggle="dropdown"
    //                            href="#"
    //                         ><i className="bi bi-gear"></i> Setting</a>
    //                         <ul className="dropdown-menu">
    //                             {menuList && menuList.includes('user') && (
    //                                 <li className="nav-item">
    //                                     <a className={menu === 'user' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
    //                                     aria-current="page"
    //                                     href="user"
    //                                     onClick={() => onClickMenu("user")}
    //                                     ><i className="bi bi-people"></i> Account</a>
    //                                 </li>
    //                             )}

    //                             {menuList && menuList.includes('role') && (
    //                                 <li className="nav-item">
    //                                     <a className={menu === 'role' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
    //                                     aria-current="page"
    //                                     href="role"
    //                                     onClick={() => onClickMenu("role")}
    //                                     ><i className="bi bi-people-fill"></i> Role Manager</a>
    //                                 </li>
    //                             )}

    //                             {menuList && (menuList.includes('user') || menuList.includes('role')) && <hr />}

    //                             {menuList && menuList.includes('office') && (
    //                                 <li className="nav-item">
    //                                     <a className={menu === 'office' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
    //                                     aria-current="page"
    //                                     href="office"
    //                                     onClick={() => onClickMenu("office")}
    //                                     ><i className="bi bi-building"></i> Office Manager</a>
    //                                 </li>
    //                             )}

    //                             {menuList && menuList.includes('department') && (
    //                                 <li className="nav-item">
    //                                     <a className={menu === 'department' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
    //                                     aria-current="page"
    //                                     href="department"
    //                                     onClick={() => onClickMenu("department")}
    //                                     ><i className="bi bi-building-fill"></i> Department Manager</a>
    //                                 </li>
    //                             )}

    //                             {menuList && (menuList.includes('office') || menuList.includes('department')) && <hr />}

    //                             {menuList && menuList.includes('workday') && (
    //                                 <li>
    //                                     <a className={menu === 'workday' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
    //                                     href="workday"
    //                                     onClick={() => onClickMenu("workday")}
    //                                     ><i className="bi bi-columns-gap"></i> Work day</a>
    //                                 </li>
    //                             )}
    //                         </ul>
    //                     </div>
    //                 </ul>
    //             </div>
    //         </div>
    //     </header>
    // ) : (
    //     ''
    // )

    return auth && auth.user && auth.status === 'success' && Global.isSuperAdmin(auth.user.type)
        ? (
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {menuList && menuList.includes('employee') && (
                <li className="nav-item">
                    <a className={menu === 'employee' ? "nav-link link-primary active" : "nav-link"}
                    onClick={() => onClickMenu("employee")}
                    ><i className="bi bi-person-workspace"></i> Employee</a>
                </li>
            )}
            {menuList && menuList.includes('timesheet') && (
                <li className="nav-item">
                    <a className={menu === 'timesheet' ? "nav-link link-primary active" : "nav-link"}
                    onClick={() => onClickMenu("timesheet")}
                    ><i className="bi bi-clock-fill"></i> Time Log</a>
                </li>
            )}
            {menuList && menuList.includes('timesheet') && (
                <li className="nav-item">
                    <a className={menu === 'schedule' ? "nav-link link-primary active" : "nav-link"}
                    onClick={() => onClickMenu("schedule")}
                    ><i className="bi bi-calendar3"></i> Schedule</a>
                </li>
            )}

            <li className="nav-item">
                <a className={menu === 'report' ? "nav-link link-primary active" : "nav-link"}
                    onClick={() => onClickMenu("report")}
                ><i className="bi bi-graph-up-arrow"></i> Report</a>
            </li>

            <div className="dropdown nav-item">
                <a className={(
                    menu === 'user'
                    || menu === 'workday'
                    || menu === 'role'
                    || menu === 'office'
                    || menu === 'department'
                    || menu === 'holiday'
                )? "dropdown-toggle nav-link link-primary active" : "dropdown-toggle nav-link"}
                    data-bs-toggle="dropdown"
                    href="#"
                ><i className="bi bi-gear"></i> Setting</a>
                <ul className="dropdown-menu">
                    {menuList && menuList.includes('user') && (
                        <li className="nav-item">
                            <a className={menu === 'user' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            aria-current="page"
                            onClick={() => onClickMenu("user")}
                            ><i className="bi bi-people"></i> Account</a>
                        </li>
                    )}

                    {menuList && menuList.includes('role') && (
                        <li className="nav-item">
                            <a className={menu === 'role' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            aria-current="page"
                            onClick={() => onClickMenu("role")}
                            ><i className="bi bi-people-fill"></i> Role Manager</a>
                        </li>
                    )}

                    {menuList && (menuList.includes('user') || menuList.includes('role')) && <hr />}

                    {menuList && menuList.includes('office') && (
                        <li className="nav-item">
                            <a className={menu === 'office' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            aria-current="page"
                            onClick={() => onClickMenu("office")}
                            ><i className="bi bi-building"></i> Office Manager</a>
                        </li>
                    )}

                    {menuList && menuList.includes('department') && (
                        <li className="nav-item">
                            <a className={menu === 'department' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            aria-current="page"
                            onClick={() => onClickMenu("department")}
                            ><i className="bi bi-building-fill"></i> Department Manager</a>
                        </li>
                    )}

                    {menuList && (menuList.includes('office') || menuList.includes('department')) && <hr />}

                    {menuList && menuList.includes('workday') && (
                        <li>
                            <a className={menu === 'workday' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            onClick={() => onClickMenu("workday")}
                            ><i className="bi bi-columns-gap"></i> Work day</a>
                        </li>
                    )}
                    {menuList && menuList.includes('holiday') && (
                        <li>
                            <a className={menu === 'holiday' ? "dropdown-item nav-link active" : "dropdown-item nav-link"}
                            onClick={() => onClickMenu("holiday")}
                            ><i className="bi bi-boxes"></i> Holiday</a>
                        </li>
                    )}
                </ul>
            </div>
        </ul>
        )
        : (
            <></>
        )
}

export default MenuAdmin
