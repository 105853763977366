import {ceil, floor} from 'lodash';
import moment from "moment"
import Global from './Global';
//import { Base64 } from "js-base64";
import { startOfWeek, endOfWeek, eachDayOfInterval, format } from "date-fns";

export default {
    formatCurrency: function (num) {

        return '$' + Number(num.toFixed(2)).toLocaleString() + ' ';
    },

    formatDatetime: function (date) {

        return date.replace(/T/, ' ').replace(/\..+/, '')
    },

    formatShortDatetime: function (date) {
        return date;
    },
    getDateTime: function (datetime, tz = 'en-US') {
        const DATE_OPTIONS = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        return new Date(datetime).toLocaleDateString(tz, DATE_OPTIONS)
    },
    // For get data converted tz from DB
    convertedUTC: function (datetime) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).format(format)
    },
    convertedDateUTC: function (datetime) {
        var format = 'YYYY-MM-DD';
        return moment.utc(datetime, format).format(format)
    },

    getMinutes: function () {
        // var format = 'mm:ss';
        // return moment.utc(new Date(), format).format(format)
        return new Date().getMinutes() + ":" + new Date().getSeconds()
    },

    //new function datetime convert from UTC to timezone
    convertUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    convertDateUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    datetimePickerUTC: function (datetime, timezone) {
        var format = 'YYYY-MM-DD HH:mm:ss';
        return moment.utc(datetime, format).tz(timezone).format(format)
    },

    /**
     * load Workhour left from Today
     * @param {string} startDate
     * @returns
     */
    worktimeLeft: function (startDate) {
        var date1 = new Date(startDate);
        var date2 = new Date();
        var cal = 1
        var Difference_In_Time = 0
        if (date2.getTime() >= date1.getTime()) {
            // To calculate the time difference of two dates
            Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var days = floor(Difference_In_Days);
            var hours = floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
            var minutes = ceil(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)

            return cal > 0
                ? days + 'D ' + hours + ':' + minutes
                : days > 0
                    ? cal * days + 'D ' + hours + ':' + minutes
                    : hours > 0
                        ? cal * hours + ':' + minutes
                        : minutes > 0
                            ? '-00:' + minutes : '-'
        }
        // To calculate the time difference of two dates
        // else {
        //     cal = -1
        //     Difference_In_Time = date1.getTime() - date2.getTime()
        // }

        return '0'

    },

    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @returns
     */
    calWorktimeLeft: function (startDate, endDate) {
        var date1 = new Date(startDate);
        var date2 = new Date(endDate);

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        var days = floor(Difference_In_Days);
        var hours = floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
        var minutes = floor(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)

        return days + 'D ' + (hours ? hours : '00') + ':' + (minutes ? minutes : '00')
    },

    worktimeLeftPercent: function (startDate, endDate) {
        var date = new Date();
        var date1 = new Date(startDate);
        var date2 = new Date(endDate);

        // To calculate the time difference of two dates
        var Difference_In_Time = date.getTime() - date1.getTime();
        var Difference_In_Time1 = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        // var Difference_In_Days1 = Difference_In_Time1 / (1000 * 3600 * 24);

        return Difference_In_Time > Difference_In_Time1 ? 100 : ceil(100 * Difference_In_Time / Difference_In_Time1)
    },

    projectStatus: (status) => {
        return status === 1 || status === '1'
            ? Global.projectStatusInProgress(true)
            : status === 2 || status === '2'
                ? Global.projectStatusClosed(true)
                : Global.projectStatusOpen(true)
    },
    getTimestamp: () => {
        var date = new Date();

        return date.getTime()
    },

    getCurrentMonthTimestamp: () => {
        // Add last months to today's date
        const my_date = new Date();
        console.log(my_date.getDate())
        if (my_date.getDate() >= 10) {
            var date = new Date(my_date.setMonth(my_date.getMonth()));
        } else {
            var date = new Date(my_date.setMonth(my_date.getMonth() - 1));
        }

        return date.getTime()
    },

    getLastMonthTimestamp: () => {
        // Add last months to today's date
        const my_date = new Date();
        var date = new Date(my_date.setMonth(my_date.getMonth() - 1));

        return date.getTime()
    },
    getTimestampFromDate: (dateTime) => {
        var date = new Date(dateTime);

        return date.getTime()
    },

    getTime: (dateTime) => {
        return moment(dateTime).format('HH:mm:ss')

        // var data = new Date(dateTime)
        // return Global.pad(data.getHours()) + ":" + Global.pad(data.getMinutes()) + ":" + Global.pad(data.getSeconds())
    },

    getDate: (dateTime) => {

        return moment(dateTime).format('DD-MM-YYYY')
    },

    getDateTimeVN: (dateTime) => {

        return moment(dateTime).format('DD-MM-YYYY HH:mm:ss')
    },

    getDateVN: (dateTime) => {

        return moment(dateTime).format('DD-MM-YYYY')
    },

    getDateLastWeek: (dateTime) => {

        return moment(dateTime).subtract(3, 'days').format('DD-MM-YYYY')
    },

    getDateYesterday: (dateTime) => {

        return moment(dateTime).subtract(1, 'days').format('DD-MM-YYYY')
    },

    getMonth: (dateTime) => {
        return moment(dateTime).format('YYYY-MM')

        // var data = new Date(dateTime)
        // return data.getFullYear() + "-" + Global.pad(data.getMonth() + 1)
    },

    getDateNormal: (dateTime) => {
        return moment(dateTime).format('YYYY-MM-DD')
    },

    changeFormatDate: (dateTime, format) => {
        return moment(dateTime).format(format)
    },

    getMonthFormat: (dateTime, format) => {
        return moment(dateTime).format(format)

        // var data = new Date(dateTime)
        // return data.getFullYear() + "-" + Global.pad(data.getMonth() + 1)
    },

    /**
     *
     * @param {string} startDate
     * @param {string} endDate
     * @returns
     */
    calWorktime: function (startTime, endTime) {
        var date = moment(new Date()).format('YYYY-MM-DD')
        var date1 = new Date(date + ' ' + startTime)
        var date2 = new Date(date + ' ' + endTime)

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        // var days = floor(Difference_In_Days);
        var hours = floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
        var minutes = floor(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)

        return {
            text:(hours ? hours : '0') + ':' + (minutes ? minutes : '0'),
            hours:hours,
            minutes:minutes
        }
    },

    calWorkHour: function(startTime, endTime) {
        var date1 = new Date(startTime)
        var date2 = new Date(endTime)

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        var days = floor(Difference_In_Days);
        var hours = 24*days + floor((Difference_In_Days - floor(Difference_In_Days)) * 24)

        return hours
    },

    formatTime: (totalMinute) => {
        var hours = floor(totalMinute/60)
        var minutes = floor((totalMinute/60 - hours) * 60)
        var seconds = floor(((totalMinute/60 - hours) * 60 - minutes) * 60)

        return {
            text:Global.pad(hours) + ':' + Global.pad(minutes),
            hours:hours,
            minutes:minutes,
            seconds:seconds
        }
    },

    transformTime: (Difference_In_Days) => {
        var days = floor(Difference_In_Days);
        var hours = 24*days + floor((Difference_In_Days - floor(Difference_In_Days)) * 24)
        var minutes = floor(((Difference_In_Days - floor(Difference_In_Days)) * 24 - hours) * 60)
        var seconds = floor(((Difference_In_Days - floor(Difference_In_Days)) * 24 * 60 - minutes) * 60)

        return {
            text:Global.pad(hours) + ':' + Global.pad(minutes),
            hours:hours,
            minutes:minutes,
            seconds:seconds
        }
    },

    // night
    calWorktimeNight: function (startTime, endTime) {
        var date1 = new Date(startTime)
        var date2 = new Date(endTime)
        // console.log(date1, date2)
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return this.transformTime(Difference_In_Days)
    },

    // day + night
    getDateFormat(format) {
        // format = 'MMMM.YYYY'
        return moment(new Date()).format(format)

    },

    getLastMonthDateFormat(format) {
        // format = 'MMMM.YYYY'
        const my_date = new Date()
        var date = new Date(my_date.setMonth(my_date.getMonth() - 1))

        return moment(date).format(format)
    },

    getMonthDateFormat(format) {
        // format = 'MMMM.YYYY'
        const my_date = new Date()
        var date = new Date(my_date.setMonth(my_date.getMonth()))

        return moment(date).format(format)
    },

    getTotalDayInMonth(year, month) {
        return new Date(year, month, 0).getDate(); // trả về số ngày trong tháng
    },

    //check in array
    inWorktime(data, id) {

        return data.find(x => x.worktime_id == id) ? true : false
    },

    inDayOfWeeks(data, id) {

        return data.find(x => x.day_of_week_id == id) ? true : false
    },

    inDayOfWeeksSchedule(data, id) {

        return data.find(x => x == id) ? true : false
    },

    // check name all array list by ID
    getDataName(data, id) {
        let days = data && (data.find(x => x.id == id))
        return days ? days.name : ''
    },

    //check schedule: team_day_type have busy or vera

    checkdayType(data, teamId, id) {
        let teamDay = data.find((x) => x.id == id && x.team_id == teamId)
        let bgColor = 'bg-light'
        if(teamDay) {
            switch (teamDay.busy_id) {
                case 1:
                    bgColor = 'bg-light'
                    break;
                case 2:
                    bgColor = 'bg-warning'
                    break;
                case 3:
                    bgColor = 'bg-danger'
                    break;
                case 4:
                    bgColor = 'bg-primary'
                    break;
                default:
                    break;
            }
        }

        return bgColor
    },

    getItemInList(data, id) {
        let days = data && (data.find(x => x.id == id))
        return days ? days : {}
    },

    // check style all array list by ID
    getStyleInList(data, id) {
        let days = data && (data.find(x => x.id == id))
        return days ? days.style : ''
    },

    // check in list string ,
    checkNameInList(list, name) {
        let item = list && (list.find(x => x == name))

        return item ? item : false
    },
}
