import axios from 'axios';
import Global from '../Global';

/**
 * @description axios instance for ajax requests
 */
const clientInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'Accept': 'application/json',
        'ContentType': 'application/json; charset=UTF-8',
        // "Access-Control-Allow-Origin" : "*",
        // "Access-Control-Allow-Headers" : "X-Requested-With"
        // 'ContentType': 'multipart/form-data',
    }
});

//validate response
clientInstance.interceptors.response.use(
    response => response,
    error => {
        if (Global.messageError(error.response)) {
            //..redirect
        }

        return Promise.reject(error);
    }
);

// Set the AUTH token for any request
clientInstance.interceptors.request.use(
    (config) => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth && auth.status === 'success') {
            const token = auth.authorisation.token
            config.headers.Accept = 'application/json';
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
    }
)

export default clientInstance;
