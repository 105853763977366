import React, {useEffect, useState} from 'react'
import Global from '../../libs/Global';
import logo from '../../assets/images/logo.png'
import MenuAdmin from './MenuAdmin';
import QRHRM from '../../assets/images/QRHRMSystem.png'
import { useNavigate, useRoutes } from 'react-router-dom';

const Header = (props) => {
  const navigate  = useNavigate()
    const {
        auth,
        toggleAuth,
        PermissionAuth,
        // setPermissionAuth,
        // getPermissionAuth
    } = props;

    const [menu, setMenu] = useState('');

    const goToHome = () => {
      navigate(process.env.REACT_APP_PUBLIC_URL + "/")
    };
    function onClickMenu(selectedMenu) {
        setMenu(selectedMenu)
        localStorage.setItem('selectedMenu', selectedMenu)
        navigate(selectedMenu)
    }

    useEffect(() => {
        setMenu(
            localStorage.getItem('selectedMenu')
                ? localStorage.getItem('selectedMenu') : ""
        )
    }, [])

    return (
        <div className="site-header mt-4">
            {/* <MenuAdmin
                menu={menu}
                setMenu={setMenu}
                onClickMenu={onClickMenu}
                auth={auth}
                PermissionAuth={PermissionAuth}
            /> */}

            <header className="navbar navbar-expand-sm bg-light sticky-top">
                <div className="container">
                    <a
                        href="/"
                        className="navbar-brand w-25"
                        onClick={() => onClickMenu("homepage")}
                        title={Global.brandName()}
                        style={{height:40}}
                    >{Global.logo(logo)}</a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <MenuAdmin
                            menu={menu}
                            setMenu={setMenu}
                            onClickMenu={onClickMenu}
                            auth={auth}
                            PermissionAuth={PermissionAuth}
                        />
                        {/* <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">

                                </a>
                            </li>
                        </ul> */}
                        {auth.status === 'success'
                        ? (
                        /*
                        <div className="text-end dropdown mb-lg-0 me-lg-3">

                            <a href="#" type="button"
                                className="d-block link-primary text-decoration-none dropdown-toggle fs-1 text-success"
                                id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-person-circle text-success link-primary fs-1"></i>
                            </a>

                            <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser">
                                <li><a className="dropdown-item" href="/"><i className="bi bi-person">{" "}</i>{auth.user.name}</a></li>
                                <li><a className="dropdown-item" href="/"><i className="bi bi-envelope">{" "}</i>{auth.user.email}</a></li>
                                <li><a className="dropdown-item" href="/">
                                    <i className="bi bi-globe2">{" "}</i>
                                    {
                                        auth.user.timezone ? auth.user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                    }</a></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" href="/" onClick={toggleAuth}><i className="bi bi-repeat">{" "}</i>Sign out</a></li>
                            </ul>
                        </div>
                        */
                        <div className="btn-group">
                            <button
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-display="static"
                                aria-expanded="false"
                                className="btn btn-white dropdown-toggle text-decoration-none border-0 link-success"
                            ><i className="bi bi-person-circle text-success fs-1 link-primary"></i></button>

                            {/* <ul className="dropdown-menu dropdown-menu-lg-end dropdown-menu-end"> */}
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={goToHome}><i
                                    className="bi bi-person">{" "}</i>{auth.user.name}</a></li>
                                <li><a className="dropdown-item" onClick={goToHome}><i
                                    className="bi bi-envelope">{" "}</i>{auth.user.email}</a></li>
                                <li><a className="dropdown-item" onClick={goToHome}>
                                    <i className="bi bi-globe2">{" "}</i>
                                    {
                                        auth.user.timezone ? auth.user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
                                    }</a></li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li><a className="dropdown-item link-primary" onClick={() => {
                                              toggleAuth(); 
                                              window.location.reload()
                                            }}>
                                    <i className="bi bi-arrow-repeat">{" "}</i>Sign out</a>
                                </li>
                            </ul>
                        </div>
                        )
                        : <img src={QRHRM} height={90} className="m-2"/>}
                    </div>

                    {/* <ul className="nav w-25 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"></ul> */}


                </div>
            </header>
        </div>

    )
}

export default Header
