import React, { Suspense, lazy, useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/layouts/Loading';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import AuthContext from './context/AuthContext';
const Login = lazy(() => import ('./components/screens/auth/Login'));
const UserManager = lazy(() => import ('./components/screens/system/UserManager'));
const RoleManager = lazy(() => import ('./components/screens/system/RoleManager'));
const OfficeManager = lazy(() => import ('./components/screens/system/OfficeManager'));
const DepartmentManager = lazy(() => import ('./components/screens/system/DepartmentManager'));
const EmployeeManager = lazy(() => import ('./components/screens/system/EmployeeManager'));
const TimesheetManager = lazy(() => import ('./components/screens/system/TimesheetManager'));
const WorkDayManager = lazy(() => import ('./components/screens/system/WorkDayManager'));
const WorkScheduleManager = lazy(() => import ('./components/screens/system/WorkScheduleManager'));
const ReportManager = lazy(() => import ('./components/screens/system/ReportManager'));
const HolidayManager  = lazy(() => import ('./components/screens/system/HolidayManager'));

function App() {
    const {
        auth,
        toggleAuth,
        PermissionAuth,
        getPermissionAuth
    } = useContext(AuthContext);

    useEffect(() => {
        // clear all user acccount system logged at 1749889118
        let resetAuth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
        // if (resetAuth && resetAuth.status == "success"
        // && resetAuth.authorisation.expires_at <= 1749889118) {
        //     localStorage.removeItem("auth");
        //     localStorage.clear()
        // }
        // check permission
        if (resetAuth && resetAuth.status == 'success' && (PermissionAuth.length < 1 || PermissionAuth == undefined)) {
            // console.log('check permission', resetAuth)
            getPermissionAuth()
        }
        // return () => {};
    }, []);

    return (
        <>
            <Header
                auth={auth}
                toggleAuth={toggleAuth}
                PermissionAuth={PermissionAuth}
            />

            <Suspense fallback={<Loading className="w-25"/>}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/user" element={<UserManager />} />
                    <Route path="/role" element={<RoleManager />} />
                    <Route path="/office" element={<OfficeManager />} />
                    <Route path="/department" element={<DepartmentManager />} />
                    <Route path="/employee" element={<EmployeeManager />} />
                    <Route path="/timesheet" element={<TimesheetManager />} />
                    <Route path="/workday" element={<WorkDayManager />} />
                    <Route path="/holiday" element={<HolidayManager />} />
                    <Route path="/schedule" element={<WorkScheduleManager />} />
                    <Route path="/report" element={<ReportManager />} />
                </Routes>
            </Suspense>
            {/* <Footer /> */}
        </>
    );
}

export default App;
